import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import LuzeanIntro from "@components/luzean-intro";
import LuzeanHosted from "@components/luzean-hosted";
import LuzeanChain from "@components/luzean-chain";
import LuzeanQualite from "@components/luzean-qualite";
import LuzeanHitl from "@components/luzean-hitl";
import LuzeanPrivacy from "@components/luzean-privacy";

import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";


const Luzean = () => {
  return (
    <Layout pageTitle="Luzean || Prompt Breeders">
      <Header />
      <LuzeanIntro />
      <LuzeanHosted />
      <LuzeanQualite />
      <LuzeanChain />
      <LuzeanPrivacy />
      <LuzeanHitl />
      <Contact />
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Luzean;
