import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const LuzeanIntro = () => {
  return (
    <section className="banner-one" id="luzean-intro">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
              <h3>
                Luzean: <br /> On élève bien <br />vos Prompts!
               
              </h3>
              
              <p>
              Luzean est notre plateforme d’hébergement de prompts.
              <br />Elle est conçue pour permettre l’intégration des technologies IA Génératives dans vos process métiers et votre architecture applicative. 
              <br />Ses capacités vont bien au-delà de ce que proposer une interface de ChatBot ou un plugin interactif. 
              
              </p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default LuzeanIntro;
