import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import shape from "@images/shapes/cta-2-shape-1.png";
import { LUZEAN_PRIVACY_DATA } from "@data";
const LuzeanPrivacy = () => {
  const { sectionTitle, image, paragraph, options } = LUZEAN_PRIVACY_DATA;
  return (
    <section className="cta-two">
      <img src={shape} className="cta-two__bg-shape-1" alt="" />
      <Container>
        <Row>
          <Col xl={6} className="col-xl-6">
            <div className="cta-two__content">
              <div className="block-title cus-pb">
                <p>{sectionTitle.text}</p>
                <h3>{sectionTitle.title}</h3>
              </div>

              <div
                className="cta-two__moc wow fadeInLeft d-xl-none"
                data-wow-duration="1500ms"
              >
                <img src={image} className="cta-one__moc-img" alt="" />
              </div>
              <div className="cta-two__text new-line">
                <p>{paragraph}</p>
              </div>
              <ul className="list-unstyled cta-two__list">
                {options.map(({ label }, index) => (
                  <li key={`options-key-${index}`}>
                    <i className="fa fa-check-circle"></i>
                    {label}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col xl={6}>
            <div
              className="cta-two__moc wow fadeInLeft d-none d-xl-block"
              data-wow-duration="1500ms"
            >
              <img src={image} className="cta-one__moc-img" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LuzeanPrivacy;
